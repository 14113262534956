.App {
  text-align: left;
  background-color: #1F2529;
  color: #fff;
  height: 100%;
  padding: 40px;
}

hr{
  background-color: #42474B;
}


.header, .footer{
  height: 10%;
}

.header .logo{
  width: 40%;
}

.visualizer{
  height: 80%;
  /* margin-bottom: 50px; */
}

.input-videolink{
  width: 100%;
  height: 100%;
  margin-left: 0;
}

.start-btn{
  width: 100%;
  font-weight: bold !important;
}

.video{
  margin-top: 20px;
  margin-bottom: 20px;
}

.input-video{
  width: 100%;
}

.stats{
  margin-top: 20px;
}

.card-item{
  border-radius: 5px;
  padding: 20px;
  background-color: #2B3135;
}

.comparision{
  height: 90%;
  margin-top: 20px;
}

.visualizer{
  margin-top: 50px;
  margin-bottom: 250px;
}

.positioning .col-3{
  text-align: center;
}

.positioning .col-6{
  text-align: center;
}

.positioning .col-3 p:first-child{
  font-size: 40px;
}

.positioning .col-6 p:first-child{
  font-size: 40px;
}

.attack-zone-titel{
  margin-top: 20px;
}

.penalty-zone{
  margin-top: 20px;
}

.progressbar{
  margin-top: 20px;
}

.two-teams .row div:first-child{
  color: #007bff;
  font-weight: bold;
}

.two-teams .row div:last-child{
  color: #dc3545;
  font-weight: bold;
}

.progressbar .row{
  justify-content: space-between;
  padding: 0 20px;
}

.progressbar-title{
  text-align: center;
}

.footer{
  /* margin-top: 150px; */
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
